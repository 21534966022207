import React, { FunctionComponent } from "react";

import "./Footer.css";

export const Footer: FunctionComponent = () => (
    <div className="footer">
        <a className="footer__link" href="http://pavelpo.cz/">
            Pavel Pospíšil&emsp;2020–{(new Date()).getFullYear()}
        </a>
        <div className="footer__cookies">
            Stránka využívá soubory cookies pro měření návštěvnosti. Návštěvou stránky souhlasíte s jejich použitím.
        </div>
    </div>
);
