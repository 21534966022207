import React, { FunctionComponent } from "react";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";

import { CharInfo } from "../../types";
import "./Char.css";

export type CharProps = {
    isBoldActive: boolean;
} & CharInfo;

const em = (value: number) => `${value}em`;

export const Char: FunctionComponent<CharProps> = ({
    left = 0,
    top = 0,
    char,
    title,
    textContent,
    isBoldActive,
}) => {
    const alert = useAlert();

    const tooltipText = title ? `${title} – klikněte pro zkopírování do schránky` : "Klikněte pro zkopírování do schránky";

    const handleCopy = () => {
        copy(char, { format: "text/plain" });
        alert.show(`Zkopírováno do schránky: ${char}`);
    };

    return (
        <div
            className="char"
            onClick={handleCopy}
            title={tooltipText}
            aria-label={title}
        >
            <div
                style={{
                    left: em(left),
                    top: em(top),
                    fontWeight: isBoldActive ? 700 : 100,
                    position: "relative"
                }}
            >
                {textContent || char}
            </div>
        </div>
    );
};
