import axios from "axios";
import React, { FunctionComponent, useEffect, useState } from "react";

import { CharInfo, EmojiData } from "../../types";
import { emojiDataListToCharInfoList, filterEmoji } from "../../utils";
import { URL } from "../../constants";
import { List } from "../List";
import { Message } from "../Message";

export const EmojiList: FunctionComponent = () => {
    const [query, setQuery] = useState<string | undefined>(undefined);
    const [emojiList, setEmojiList] = useState<CharInfo[] | undefined>(undefined);
    const [filteredEmojiList, setFilteredEmojiList] = useState<CharInfo[] | undefined>(undefined);

    useEffect(() => {
        axios.get(URL).then(response => {
            const emojiDataList: EmojiData[] = response.data;
            setEmojiList(emojiDataListToCharInfoList(emojiDataList));
        });
    }, []);

    useEffect(() => {
        setFilteredEmojiList(filterEmoji(emojiList, query));
    }, [emojiList, query]);

    return (
        <>
            <input
                style={{
                    width: 300,
                    margin: "30px auto",
                    background: "#FAFAFA",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: 30,
                }}
                value={query}
                onChange={e => setQuery(e.target.value)}
                placeholder="Název emoji v anglickém nebo českém jazyce"
                autoFocus={true}
                onFocus={event => event.target.select()}
            />
            {filteredEmojiList === undefined ? (
                <Message>Načítám…</Message>
            ) : filteredEmojiList.length === 0 ? (
                <Message>Žádné výsledky</Message>
            ) : (
                <>
                    {query !== undefined && query !== "" ? (
                        <h1>Vyhledané</h1>
                    ) : null}
                    <List
                        isBoldActive={false}
                        characterList={filteredEmojiList}
                    />
                </>
            )}
        </>
    );
}
