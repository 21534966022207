import React, { FunctionComponent } from "react";
import { CharInfo } from "../../types";

import { Char } from "../Char";
import "./List.css";

export type ListProps = {
    characterList: CharInfo[];
    isBoldActive?: boolean;
}

export const List: FunctionComponent<ListProps> = ({ characterList, isBoldActive = true }) => (
    <div className="list">
        {characterList.map(({ id, title, textContent, char, left, top }) => (
            <Char key={id} {...{ id, title, textContent, char, left, top, isBoldActive }} />
        ))}
    </div>
);
