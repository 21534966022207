import { FunctionComponent, ReactElement } from "react";
import { useLocation } from "react-router-dom";

export type LocationProviderChildrenProps = {
    hash: string,
    pathname: string,
    search: string,
}

export type LocationProviderProps = {
    children: (props: LocationProviderChildrenProps) => ReactElement,
}

export const LocationProvider: FunctionComponent<LocationProviderProps> = ({ children }) => {
    const { hash, pathname, search } = useLocation();

    return children({
        hash,
        pathname,
        search,
    });
};
