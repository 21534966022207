import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import "./Menu.css";

export const Menu: FunctionComponent = () => (
    <div className="menu">
        <Link to="/" className="app__link">Znaky</Link>
        <Link to="/emoji" className="app__link">Emoji</Link>
        <Link to="/hodnoceni" className="app__link">Hodnocení</Link>
    </div>
);
