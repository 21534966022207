import { czech } from "./translations";
import { CharInfo, EmojiData } from "./types";

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const emojiDataListToCharInfoList = (emojiDataList: EmojiData[]): CharInfo[] => {
    return emojiDataList.map((emojiData) => {
        const czechTranslation = czech[emojiData.codePoint];
        if (czechTranslation === undefined) {
            console.log(`${emojiData.codePoint}: Znak bez překladu`);
        }
        return {
            id: emojiData.codePoint,
            char: emojiData.character,
            title: capitalize(`${emojiData.unicodeName} ${emojiData.character} (${czechTranslation?.toLowerCase() ?? "bez překladu"})`),
            top: -0.05,
        };
    });
}

export const filterEmoji = (
    emojiList: CharInfo[] | undefined,
    query: string | undefined,
): CharInfo[] | undefined => {
    if (emojiList === undefined) {
        return undefined;
    }

    if (emojiList.length === 0) {
        return [];
    }

    const idList = emojiList.map(charInfo => charInfo.id);

    const uniqueEmojiList = emojiList.filter((charInfo, index) => idList.indexOf(charInfo.id) === index)

    if (query === undefined) {
        return uniqueEmojiList;
    }

    return uniqueEmojiList.filter(charInfo => charInfo.title.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
}
