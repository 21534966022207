export const czech: { [codePoint: string]: string } = {
    "1F600": "úsměvem obličej",
    "1F603": "S úsměvem obličej s velkýma očima",
    "1F604": "S úsměvem obličej s úsměvem oči",
    "1F601": "zářivý obličej s úsměvem oči",
    "1F606": "usmívající se zmírnění obličeje",
    "1F605": "úsměvem tvář s potem",
    "1F923": "Válcování na podlaze se směje",
    "1F602": "Tvář se slzami radosti",
    "1F642": "Mírně usmívající se tvář",
    "1F643": "vzhůru nohama",
    "1F609": "mrkající obličej",
    "1F60A": "Usmívající se tvář s úsměvem oči",
    "1F607": "Usmívající se tvář s halo",
    "1F970": "Usmívající se tvář se srdíčky",
    "1F60D": "Usmívající se tvář se srdcem",
    "1F929": "hvězdy",
    "1F618": "tvář fouká polibek",
    "1F617": "líbání obličeje",
    "263A FE0F": "usměvavá tvář",
    "1F61A": "líbání tvář se zavřenýma očima",
    "1F619": "Líbání tvář s úsměvem oči",
    "1F972": "Usmívající se tvář s slzou",
    "1F60B": "obličej vychutnat jídlo",
    "1F61B": "tvář s jazykem",
    "1F61C": "mrknutí tvář s jazykem",
    "1F92A": "zany face",
    "1F61D": "Mrnhing tvář s jazykem",
    "1F911": "Peněžní tvář",
    "1F917": "objímání obličeje",
    "1F92D": "Tvář s rukou přes ústa",
    "1F92B": "Shushing Face",
    "1F914": "myšlení tváře",
    "1F910": "tvář zip",
    "1F928": "obličej se zvednutým obočím",
    "1F610": "neutrální obličej",
    "1F611": "bezvýrazný obličej",
    "1F636": "obličej bez úst",
    "1F60F": "zamčující obličej",
    "1F612": "nevědomý obličej",
    "1F644": "obličej s válcovacíma očima",
    "1F62C": "grimacing face",
    "1F925": "ležící obličej",
    "1F60C": "Zvýrazněná tvář",
    "1F614": "zamyšlený obličej",
    "1F62A": "ospalý obličej",
    "1F924": "slintovací face",
    "1F634": "spací obličej",
    "1F637": "Obličej s lékařskou maskou",
    "1F912": "Tvář s teploměrem",
    "1F915": "tvář s obvazem hlavy",
    "1F922": "Nauseated face",
    "1F92E": "obličej zvracení",
    "1F927": "kýchání obličeje",
    "1F975": "horká tvář",
    "1F976": "studený obličej",
    "1F974": "woozy face",
    "1F635": "závratný obličej",
    "1F92F": "explodující hlava",
    "1F920": "kovbojský klobouk lícem",
    "1F973": "Parting Face",
    "1F978": "maskovaný obličej",
    "1F60E": "Usmívající se tvář s sluneční brýle",
    "1F913": "Nerd Face",
    "1F9D0": "tvář s monoklem",
    "1F615": "zmatená tvář",
    "1F61F": "znepokojený obličej",
    "1F641": "Mírně zamračená tvář",
    "2639 FE0F": "zamračený obličej",
    "1F62E": "tvář s otevřenými ústy",
    "1F62F": "hushed face",
    "1F632": "udivený obličej",
    "1F633": "propláchnutý obličej",
    "1F97A": "Obličeje",
    "1F626": "zamračený obličej s otevřenými ústy",
    "1F627": "úzkost",
    "1F628": "strašný obličej",
    "1F630": "Nervózní tvář s potem",
    "1F625": "smutný, ale ulevilo se tvář",
    "1F622": "pláč lícem",
    "1F62D": "hlasitě plakat obličej",
    "1F631": "obličej křičí ve strachu",
    "1F616": "zmatený obličej",
    "1F623": "vytrvala tvář",
    "1F61E": "zklamaný obličej",
    "1F613": "Sward tvář s potem",
    "1F629": "unavený obličej",
    "1F62B": "unavený obličej",
    "1F971": "Zívání tváře",
    "1F624": "obličej s párou z nosu",
    "1F621": "Pouting Face",
    "1F620": "naštvaný obličej",
    "1F92C": "Tvář se symboly na ústech",
    "1F608": "Usmívající se tvář s rohy",
    "1F47F": "Rozzlobený obličej s rohy",
    "1F480": "lebka",
    "2620 FE0F": "lebka a zkřížené hnáty",
    "1F4A9": "Hromadu Poo",
    "1F921": "klaun face",
    "1F479": "ogre",
    "1F47A": "šotek",
    "1F47B": "duch",
    "1F47D": "mimozemšťan",
    "1F47E": "Alien Monster",
    "1F916": "robot",
    "1F63A": "usmívající se kočka",
    "1F638": "S úsměvem kočka s úsměvem oči",
    "1F639": "kočka se slzami radosti",
    "1F63B": "S úsměvem kočka se srdcem",
    "1F63C": "kočka s úsměvem",
    "1F63D": "líbání kočka",
    "1F640": "unavený kočka",
    "1F63F": "pláč kočičí",
    "1F63E": "Pouting Cat",
    "1F648": "vidět-no-evil opice",
    "1F649": "slyšet-no-evil opice",
    "1F64A": "mluvit-no-evil opice",
    "1F48B": "otisk rtů",
    "1F48C": "milostný dopis",
    "1F498": "srdce s šipkou",
    "1F49D": "Srdce s stuhou",
    "1F496": "šumivé srdce",
    "1F497": "pěstování srdce",
    "1F493": "tlukoucí srdce",
    "1F49E": "otočné srdce",
    "1F495": "dvě srdce",
    "1F49F": "Dekorace srdce",
    "2763 FE0F": "vykřičník srdce",
    "1F494": "zlomené srdce",
    "2764 FE0F": "červené srdce",
    "1F9E1": "oranžové srdce",
    "1F49B": "žluté srdce",
    "1F49A": "zelené srdce",
    "1F499": "modré srdce",
    "1F49C": "fialové srdce",
    "1F90E": "hnědé srdce",
    "1F5A4": "černé srdce",
    "1F90D": "bílé srdce",
    "1F4AF": "Sto bodů",
    "1F4A2": "Hněv symbol",
    "1F4A5": "kolize",
    "1F4AB": "dizický",
    "1F4A6": "kapky potu",
    "1F4A8": "pryč",
    "1F573 FE0F": "otvor",
    "1F4A3": "bombardovat",
    "1F4AC": "řečový balón",
    "1F441 FE0F 200D 1F5E8 FE0F": "oko v bublinu",
    "1F5E8 FE0F": "Levá řečová bublina",
    "1F5EF FE0F": "pravý hněv bublina",
    "1F4AD": "myšlenka balón",
    "1F4A4": "zzzz",
    "1F44B": "mává rukou",
    "1F91A": "zvedl ruku",
    "1F590 FE0F": "Ruka s prsty splayed",
    "1F590 1F3FB": "Ruka s prsty Splayed: Světle pleťový tón",
    "1F590 1F3FC": "Ruka s prsty SPlayed: středně lehký pleťový tón",
    "1F590 1F3FD": "Ruka s prsty SPlayed: Střední tón pleti",
    "1F590 1F3FE": "Ruka s prsty SPlayed: Středně tmavě tón pleti",
    "1F590 1F3FF": "Ruka s prsty SPlayed: Tmavý tón pleti",
    "270B": "Zvedl ruku",
    "1F596": "Vulcan Salute",
    "1F44C": "V pořádku",
    "1F90C": "Přitáhl prsty",
    "1F90F": "svírání rukou",
    "270C FE0F": "vítězství rukou",
    "270C 1F3FB": "Vítězství Ruka: Světle pleťový tón",
    "270C 1F3FC": "Vítězství Hand: středně lehký pleťový tón",
    "270C 1F3FD": "Vítězství ruka: střední tón pleti",
    "270C 1F3FE": "Vítězství Hand: Středně tmavě tón pleti",
    "270C 1F3FF": "Vítězství ruku: tmavý tón pleti",
    "1F91E": "překřížené prsty",
    "1F91F": "láska - gesto",
    "1F918": "znamení rohů",
    "1F919": "Zavolej mi ruku",
    "1F448": "backhand index ukazující vlevo",
    "1F449": "backhand index ukazující správně",
    "1F446": "backhand index směřující nahoru",
    "1F595": "prostředníček",
    "1F447": "backhand index směřující dolů",
    "261D FE0F": "index směřující nahoru",
    "261D 1F3FB": "Rejstřík směřující nahoru: Světle pleťový tón",
    "261D 1F3FC": "Rejstřík směřující nahoru: středně lehký pleťový tón",
    "261D 1F3FD": "Rejstřík směřující nahoru: Střední tón pleti",
    "261D 1F3FE": "Index ukazující nahoru: Středně tmavá pleťová tón",
    "261D 1F3FF": "Index směřující nahoru: Tmavý tón pleti",
    "1F44D": "palec nahoru",
    "1F44E": "palec dolů",
    "270A": "Zvedl pěst",
    "1F44A": "blížící se pěst",
    "1F91B": "pěst vlevo",
    "1F91C": "pěst vpravo",
    "1F44F": "tleskání rukou",
    "1F64C": "zvyšování rukou",
    "1F450": "otevřené ruce",
    "1F932": "dlanějí dohromady",
    "1F91D": "handshake",
    "1F64F": "Skládané ruce",
    "270D FE0F": "psaní rukou",
    "270D 1F3FB": "Psaní rukou: Světle pleťový tón",
    "270D 1F3FC": "Psaní rukou: středně lehký pleťový tón",
    "270D 1F3FD": "Psaní rukou: střední tón pleti",
    "270D 1F3FE": "Psaní ruky: Středně tmavé pleťový tón",
    "270D 1F3FF": "Psaní rukou: Tmavý tón pleti",
    "1F485": "lak na nehty",
    "1F933": "selfie",
    "1F4AA": "ohnuté biceps",
    "1F9BE": "Mechanické rameno",
    "1F9BF": "mechanická noha",
    "1F9B5": "noha",
    "1F9B6": "chodidlo",
    "1F442": "ucho",
    "1F9BB": "EAR se sluchadlem",
    "1F443": "nos",
    "1F9E0": "mozek",
    "1FAC0": "Anatomické srdce",
    "1FAC1": "plíce",
    "1F9B7": "zub",
    "1F9B4": "kost",
    "1F440": "očima",
    "1F441 FE0F": "oko",
    "1F445": "jazyk",
    "1F444": "pusa",
    "1F476": "dítě",
    "1F9D2": "dítě",
    "1F466": "chlapec",
    "1F467": "dívka",
    "1F9D1": "osoba",
    "1F471": "Osoba: blond vlasy",
    "1F468": "muž",
    "1F9D4": "Člověk: vousy",
    "1F468 200D 1F9B0": "Muž: červené vlasy",
    "1F469": "žena",
    "1F9D1 200D 1F9B0": "Osoba: červené vlasy",
    "1F469 200D 1F9B1": "Žena: kudrnaté vlasy",
    "1F9D1 200D 1F9B1": "Osoba: kudrnaté vlasy",
    "1F469 200D 1F9B3": "Žena: bílé vlasy",
    "1F9D1 200D 1F9B3": "Osoba: bílé vlasy",
    "1F469 200D 1F9B2": "Žena: plešatý",
    "1F9D1 200D 1F9B2": "Osoba: plešatý",
    "1F471 200D 2640 FE0F": "Žena: blond vlasy",
    "1F9D3": "starší osoba",
    "1F474": "starý muž",
    "1F475": "stará žena",
    "1F64D": "osoba zamračená",
    "1F64E": "Osoba Očeping",
    "1F645": "osoba směřuje no",
    "1F646": "Osoba ukázala OK",
    "1F481": "Osoba sklopná ruka",
    "1F64B": "Osoba zvyšování rukou",
    "1F9CF": "hluchá osoba",
    "1F647": "osoba, která se blíží",
    "1F926": "Osoba facepalming",
    "1F937": "Osoba pokrčil rameny",
    "1F9D1 200D 2695 FE0F": "zdravotnický pracovník",
    "1F468 200D 2695 FE0F": "Muž zdraví pracovník",
    "1F469 200D 2695 FE0F": "Žena zdravotnický pracovník",
    "1F9D1 200D 1F393": "student",
    "1F468 200D 1F393": "muž student",
    "1F469 200D 1F393": "žena student",
    "1F9D1 200D 1F3EB": "učitel",
    "1F468 200D 1F3EB": "učití člověka",
    "1F469 200D 1F3EB": "učitelka ženy",
    "1F9D1 200D 2696 FE0F": "soudce",
    "1F468 200D 2696 FE0F": "muž soudce",
    "1F469 200D 2696 FE0F": "Žena soudce",
    "1F9D1 200D 1F33E": "zemědělec",
    "1F468 200D 1F33E": "muž farmář",
    "1F469 200D 1F33E": "žena farmář",
    "1F9D1 200D 1F373": "kuchařka",
    "1F468 200D 1F373": "muž vařit",
    "1F469 200D 1F373": "žena vařit",
    "1F9D1 200D 1F527": "mechanik",
    "1F468 200D 1F527": "Muž Mechanic",
    "1F469 200D 1F527": "Žena mechanik",
    "1F9D1 200D 1F3ED": "tovární dělník",
    "1F468 200D 1F3ED": "muž tovární dělník",
    "1F469 200D 1F3ED": "Žena tovární dělník",
    "1F9D1 200D 1F4BC": "úředník",
    "1F468 200D 1F4BC": "Man Office Worker",
    "1F469 200D 1F4BC": "Žena úřednice pracovník",
    "1F9D1 200D 1F52C": "vědec",
    "1F468 200D 1F52C": "Vědec člověka",
    "1F469 200D 1F52C": "žena vědec",
    "1F9D1 200D 1F4BB": "technolog",
    "1F468 200D 1F4BB": "Muž technolog",
    "1F469 200D 1F4BB": "Žena technolog",
    "1F9D1 200D 1F3A4": "zpěvák",
    "1F468 200D 1F3A4": "muž zpěvák",
    "1F469 200D 1F3A4": "žena zpěvák",
    "1F9D1 200D 1F3A8": "umělec",
    "1F468 200D 1F3A8": "muž umělec",
    "1F469 200D 1F3A8": "žena umělec",
    "1F9D1 200D 2708 FE0F": "pilot",
    "1F468 200D 2708 FE0F": "Man pilot",
    "1F469 200D 2708 FE0F": "žena pilot",
    "1F9D1 200D 1F680": "astronaut",
    "1F468 200D 1F680": "muž astronaut",
    "1F469 200D 1F680": "Žena astronaut",
    "1F9D1 200D 1F692": "hasič",
    "1F468 200D 1F692": "muž hasič",
    "1F469 200D 1F692": "Žena hasič",
    "1F46E": "policista",
    "1F575 FE0F": "detektivní",
    "1F575 1F3FB": "Detektiv: Světlo pleťový tón",
    "1F575 1F3FC": "Detektiv: středně lehký pleťový tón",
    "1F575 1F3FD": "Detektiv: Střední tón pleti",
    "1F575 1F3FE": "Detektiv: Středně tmavý tón pleti",
    "1F575 1F3FF": "Detektiv: Tmavý tón pleti",
    "1F575 FE0F 200D 2642 FE0F": "Muž Detektiv",
    "1F575 1F3FB 200D 2642 FE0F": "Muž Detektiv: Světle pleťový tón",
    "1F575 1F3FC 200D 2642 FE0F": "Muž Detektiv: středně lehký pleťový tón",
    "1F575 1F3FD 200D 2642 FE0F": "Muž Detektiv: Střední tón pleti",
    "1F575 1F3FE 200D 2642 FE0F": "Muž Detektiv: Středně tmavý tón pleti",
    "1F575 1F3FF 200D 2642 FE0F": "Člověk detektiv: tmavý tón pleti",
    "1F575 FE0F 200D 2640 FE0F": "Žena detektiv",
    "1F575 1F3FB 200D 2640 FE0F": "Žena detektiv: lehký pleťový tón",
    "1F575 1F3FC 200D 2640 FE0F": "Žena detektiv: středně lehký pleťový tón",
    "1F575 1F3FD 200D 2640 FE0F": "Žena detektiv: střední tón pleti",
    "1F575 1F3FE 200D 2640 FE0F": "Žena detektiv: středně tmavé pleťový tón",
    "1F575 1F3FF 200D 2640 FE0F": "Žena detektiv: tmavý tón pleti",
    "1F482": "hlídat",
    "1F977": "ninja",
    "1F477": "stavební dělník",
    "1F934": "princ",
    "1F478": "princezna",
    "1F473": "osoba nosí turban",
    "1F472": "osoba s Skullcap",
    "1F9D5": "Žena s šátek",
    "1F935": "osoba ve smokingu",
    "1F470": "osoba se závojem",
    "1F930": "těhotná žena",
    "1F931": "kojení",
    "1F469 200D 1F37C": "Žena krmení baby",
    "1F468 200D 1F37C": "Muž krmení baby",
    "1F9D1 200D 1F37C": "osoba krmení baby",
    "1F47C": "baby angel",
    "1F385": "Ježíšek",
    "1F936": "Paní Clausová",
    "1F9D1 200D 1F384": "mx Claus",
    "1F9B8": "super hrdina",
    "1F9B9": "Supervillain",
    "1F9D9": "kouzelník",
    "1F9DA": "víla",
    "1F9DB": "upír",
    "1F9DC": "merperson",
    "1F9DD": "Elf",
    "1F9DE": "genie",
    "1F9DF": "zombie",
    "1F486": "osoba dostat masáž",
    "1F487": "osoba dostat účes",
    "1F6B6": "Osoba, chůze",
    "1F9CD": "osoba stojící",
    "1F9CE": "osoba klečína",
    "1F9D1 200D 1F9AF": "Osoba s bílou hůlkou",
    "1F468 200D 1F9AF": "Muž s bílou hůl",
    "1F469 200D 1F9AF": "Žena s bílou hůl",
    "1F9D1 200D 1F9BC": "osoba v motorizovaném invalidním vozíku",
    "1F468 200D 1F9BC": "Muž v motorizovaném invalidním vozíku",
    "1F469 200D 1F9BC": "Žena v motorizovaném invalidním vozíku",
    "1F9D1 200D 1F9BD": "Osoba v ručním invalidním vozíku",
    "1F468 200D 1F9BD": "Muž v ručním invalidním vozíku",
    "1F469 200D 1F9BD": "Žena v ručním invalidním vozíku",
    "1F3C3": "osoba běží",
    "1F483": "Žena tančí",
    "1F57A": "muž tanec",
    "1F574 FE0F": "Osoba v obleku levitující",
    "1F574 1F3FB": "Osoba v obleku Levitace: Světle pleťový tón",
    "1F574 1F3FC": "Osoba v obleku Levitace: středně lehký pleťový tón",
    "1F574 1F3FD": "Osoba v obleku Levitace: Střední tón pleti",
    "1F574 1F3FE": "Osoba v obleku Levitace: Středně tmavě tón pleti",
    "1F574 1F3FF": "Osoba v obleku Levitace: Tmavý tón pleti",
    "1F46F": "lidé s ušimi zajíček",
    "1F9D6": "Osoba v parní místnosti",
    "1F9D7": "Osoba lezení",
    "1F93A": "Osoba oplocení",
    "1F3C7": "koňské dostihy",
    "26F7 FE0F": "lyžař",
    "1F3C2": "snowboardista",
    "1F3CC FE0F": "Osoba golfing",
    "1F3CC 1F3FB": "Osoba golf: Světle pleťový tón",
    "1F3CC 1F3FC": "Osoba Golfing: středně lehký pleťový tón",
    "1F3CC 1F3FD": "Osoba Golfing: Střední tón pleti",
    "1F3CC 1F3FE": "Osoba Golfing: Středně tmavé pleťový tón",
    "1F3CC 1F3FF": "Osoba Golfing: Tmavý tón pleti",
    "1F3CC FE0F 200D 2642 FE0F": "Muž golfing",
    "1F3CC 1F3FB 200D 2642 FE0F": "Muž golf: Světle pleťový tón",
    "1F3CC 1F3FC 200D 2642 FE0F": "Muž golf: středně lehký pleťový tón",
    "1F3CC 1F3FD 200D 2642 FE0F": "Muž golfů: střední tón kůže",
    "1F3CC 1F3FE 200D 2642 FE0F": "MAN GOLFING: Středně tmavé pleťový tón",
    "1F3CC 1F3FF 200D 2642 FE0F": "MAN GOLFING: Tmavý pleťový tón",
    "1F3CC FE0F 200D 2640 FE0F": "Žena golfing",
    "1F3CC 1F3FB 200D 2640 FE0F": "Žena golfů: světle pleťový tón",
    "1F3CC 1F3FC 200D 2640 FE0F": "Žena golfovat: středně lehký pleťový tón",
    "1F3CC 1F3FD 200D 2640 FE0F": "Žena golf: střední tón pleti",
    "1F3CC 1F3FE 200D 2640 FE0F": "Žena golf: středně tmavé pleťový tón",
    "1F3CC 1F3FF 200D 2640 FE0F": "Žena golfovat: tmavě tón pleti",
    "1F3C4": "osoba surfování",
    "1F6A3": "Osoba veslování lodí",
    "1F3CA": "osoba plavání",
    "26F9 FE0F": "osoba skákací míč",
    "26F9 1F3FB": "Osoba skákací míč: lehký pleťový tón",
    "26F9 1F3FC": "Osoba skákací míč: středně lehký pleťový tón",
    "26F9 1F3FD": "Osoba skákací míč: střední tón pleti",
    "26F9 1F3FE": "Osoba skákací míč: středně tmavý tón pleti",
    "26F9 1F3FF": "Osoba skákací míč: tmavý tón pleti",
    "26F9 FE0F 200D 2642 FE0F": "muž skákací míč",
    "26F9 1F3FB 200D 2642 FE0F": "Muž skákací míč: světle pleťový tón",
    "26F9 1F3FC 200D 2642 FE0F": "Muž skákací míč: středně lehký pleťový tón",
    "26F9 1F3FD 200D 2642 FE0F": "MAN BOUNCING BALL: Střední tón pleti",
    "26F9 1F3FE 200D 2642 FE0F": "Muž skákací míč: střední tmavě tón pleti",
    "26F9 1F3FF 200D 2642 FE0F": "Muž skákací míč: tmavý pleťový tón",
    "26F9 FE0F 200D 2640 FE0F": "Žena skákací míč",
    "26F9 1F3FB 200D 2640 FE0F": "Žena skákací míč: světle pleťový tón",
    "26F9 1F3FC 200D 2640 FE0F": "Žena skákací míč: středně lehký pleťový tón",
    "26F9 1F3FD 200D 2640 FE0F": "Žena skákací míč: střední tón kůže",
    "26F9 1F3FE 200D 2640 FE0F": "Žena skákací míč: středně tmavé pleťový tón",
    "26F9 1F3FF 200D 2640 FE0F": "Žena skákací míč: tmavý pleťový tón",
    "1F3CB FE0F": "osoba zvedání závaží",
    "1F3CB 1F3FB": "Osoba zvedání závaží: lehký pleťový tón",
    "1F3CB 1F3FC": "Osoba zvedání závaží: středně lehký pleťový tón",
    "1F3CB 1F3FD": "Osoba zvedání závaží: střední tón pleti",
    "1F3CB 1F3FE": "Osoba zvedání závaží: středně tmavě tón pleti",
    "1F3CB 1F3FF": "Osoba zvedání závaží: tmavý tón pleti",
    "1F3CB FE0F 200D 2642 FE0F": "Muž zvedání závaží",
    "1F3CB 1F3FB 200D 2642 FE0F": "Muž zvedání závaží: Světle pleťový tón",
    "1F3CB 1F3FC 200D 2642 FE0F": "Muž zvedání závaží: středně lehký pleťový tón",
    "1F3CB 1F3FD 200D 2642 FE0F": "Muž zvedání závaží: střední tón pleti",
    "1F3CB 1F3FE 200D 2642 FE0F": "Muž zvedání závaží: středně tmavě tón pleti",
    "1F3CB 1F3FF 200D 2642 FE0F": "Muž zvedání závaží: tmavý tón pleti",
    "1F3CB FE0F 200D 2640 FE0F": "Žena zvedání závaží",
    "1F3CB 1F3FB 200D 2640 FE0F": "Žena zvedání závaží: světle pleťový tón",
    "1F3CB 1F3FC 200D 2640 FE0F": "Žena zvedání závaží: středně lehký pleťový tón",
    "1F3CB 1F3FD 200D 2640 FE0F": "Žena zvedání závaží: střední tón pleti",
    "1F3CB 1F3FE 200D 2640 FE0F": "Žena zvedání závaží: středně tmavě tón pleti",
    "1F3CB 1F3FF 200D 2640 FE0F": "Žena zvedání závaží: tmavě pleťový tón",
    "1F6B4": "Osoba Cykloturistika",
    "1F6B5": "Osoba horské cyklistiky",
    "1F938": "osoba cartwheeling",
    "1F93C": "lidé zápasí",
    "1F93D": "osoba hrající vodní pólo",
    "1F93E": "osoba hrající handball",
    "1F939": "osoba žonglování",
    "1F9D8": "Osoba v lotosové pozici",
    "1F6C0": "Osoba vany",
    "1F6CC": "osoba v lůžku",
    "1F9D1 200D 1F91D 200D 1F9D1": "lidé drží za ruce",
    "1F46D": "ženy drží za ruce",
    "1F469 1F3FB 200D 1F91D 200D 1F469 1F3FC": "Ženy drží za ruce: lehký tón pleť, středně lehký pleť tón",
    "1F46D 1F3FC": "Ženy drží za ruce: středně lehký pleťový tón",
    "1F469 1F3FC 200D 1F91D 200D 1F469 1F3FD": "Ženy drží za ruce: středně lehký pleťový tón, středně pleťový tón",
    "1F46D 1F3FD": "Ženy drží za ruce: Střední tón pleti",
    "1F469 1F3FD 200D 1F91D 200D 1F469 1F3FE": "Ženy drží za ruce: střední tón pleti, středně tmavé pleťový tón",
    "1F46D 1F3FE": "Ženy drží za ruce: středně tmavé pleťový tón",
    "1F469 1F3FE 200D 1F91D 200D 1F469 1F3FF": "Ženy drží za ruce: Středně tmavé pleťový tón, tmavý tón pleti",
    "1F46D 1F3FF": "Ženy drží za ruce: tmavě tón pleti",
    "1F46B": "Žena a muž, který držel ruce",
    "1F469 1F3FB 200D 1F91D 200D 1F468 1F3FC": "Žena a muž, který držel za ruce: světle pleťový tón, středně lehký pleť tón",
    "1F46B 1F3FC": "Žena a muž, který držel za ruce: středně lehký pleťový tón",
    "1F469 1F3FC 200D 1F91D 200D 1F468 1F3FD": "Žena a muž, který držel za ruce: středně lehký pleťový tón, střední tón pleti",
    "1F46B 1F3FD": "Žena a muž, který držel za ruce: střední tón pleti",
    "1F469 1F3FD 200D 1F91D 200D 1F468 1F3FE": "Žena a muž, který držel za ruce: střední tón pleti, středně tmavé pleťový tón",
    "1F46B 1F3FE": "Žena a muž, který držel za ruce: středně tmavé pleťový tón",
    "1F469 1F3FE 200D 1F91D 200D 1F468 1F3FF": "Žena a muž, který držel ruce: středně tmavé pleťový tón, tmavý pleťový tón",
    "1F46B 1F3FF": "Žena a muž, který držel ruce: tmavě tón pleti",
    "1F46C": "Muži drží za ruce",
    "1F468 1F3FB 200D 1F91D 200D 1F468 1F3FC": "Muži, drželi se za ruce: lehký pleťový tón, středně lehký pleťový tón",
    "1F46C 1F3FC": "Muži drží za ruce: středně lehký pleťový tón",
    "1F468 1F3FC 200D 1F91D 200D 1F468 1F3FD": "Muži, drželi se za ruce: středně lehký pleťový tón, střední tón pleti",
    "1F46C 1F3FD": "Muži, drželi se za ruce: střední tón pleti",
    "1F468 1F3FD 200D 1F91D 200D 1F468 1F3FE": "Muži, drželi se za ruce: střední tón pleti, středně tmavé pleťový tón",
    "1F46C 1F3FE": "Muži, drželi se za ruce: střední tmavě tón pleti",
    "1F468 1F3FE 200D 1F91D 200D 1F468 1F3FF": "Muži, drželi se za ruce: středně tmavě tón pleti, tmavě tón pleti",
    "1F46C 1F3FF": "Muži, drželi se za ruce: tmavě pleťový tón",
    "1F48F": "pusa",
    "1F469 200D 2764 FE0F 200D 1F48B 200D 1F468 ; fully-qualified": "polibek: žena, muž",
    "1F468 200D 2764 FE0F 200D 1F48B 200D 1F468 ; fully-qualified": "polibek: muž, muž",
    "1F469 200D 2764 FE0F 200D 1F48B 200D 1F469 ; fully-qualified": "polibek: žena, žena",
    "1F491": "pár se srdcem",
    "1F469 200D 2764 FE0F 200D 1F468": "Pár se srdcem: žena, muž",
    "1F468 200D 2764 FE0F 200D 1F468": "Pár se srdcem: muž, muž",
    "1F469 200D 2764 FE0F 200D 1F469": "Pár se srdcem: žena, žena",
    "1F46A": "rodina",
    "1F468 200D 1F469 200D 1F466": "Rodina: muž, žena, chlapec",
    "1F469 200D 1F469 200D 1F466": "Rodina: žena, žena, chlapec",
    "1F468 200D 1F466": "Rodina: muž, chlapec",
    "1F469 200D 1F466": "Rodina: Žena, chlapec",
    "1F5E3 FE0F": "mluvící hlava",
    "1F464": "Busta v silueta",
    "1F465": "busty v silueta",
    "1FAC2": "lidé objímání",
    "1F463": "stopy",
    "1F435": "opičí obličej",
    "1F412": "opice",
    "1F98D": "gorila",
    "1F9A7": "orangutan",
    "1F436": "psí obličej",
    "1F415": "Pes",
    "1F9AE": "vodící pes",
    "1F415 200D 1F9BA": "Servisní pes",
    "1F429": "pudl",
    "1F43A": "vlk",
    "1F98A": "liška",
    "1F99D": "mýval",
    "1F431": "kočičí tvář",
    "1F408": "kočka",
    "1F981": "lev",
    "1F42F": "Tiger Face",
    "1F405": "tygr",
    "1F406": "leopard",
    "1F434": "obličej koně",
    "1F40E": "kůň",
    "1F984": "jednorožec",
    "1F993": "zebra",
    "1F98C": "Jelen",
    "1F9AC": "bison",
    "1F42E": "kráva",
    "1F402": "vůl",
    "1F403": "vodní buvol",
    "1F404": "kráva",
    "1F437": "prasečík",
    "1F416": "prase",
    "1F417": "kanec",
    "1F43D": "prase nos",
    "1F40F": "RAM",
    "1F411": "ovce",
    "1F410": "koza",
    "1F42A": "velbloud",
    "1F42B": "dvoulomus velbloud",
    "1F999": "lama",
    "1F992": "žirafa",
    "1F418": "slon",
    "1F9A3": "mamut",
    "1F98F": "nosorožec",
    "1F99B": "hroch",
    "1F42D": "Myšová obličej",
    "1F401": "myš",
    "1F400": "krysa",
    "1F439": "křeček",
    "1F430": "králičí obličej",
    "1F407": "králičí",
    "1F43F FE0F": "chipmunk",
    "1F9AB": "bobr",
    "1F994": "ježek",
    "1F987": "netopýr",
    "1F43B": "medvěd",
    "1F428": "koala",
    "1F43C": "panda",
    "1F9A5": "lenochod",
    "1F9A6": "vydra",
    "1F9A8": "skunk",
    "1F998": "klokan",
    "1F9A1": "jezevec",
    "1F43E": "Paw Prints",
    "1F983": "krocan",
    "1F414": "kuře",
    "1F413": "kohout",
    "1F423": "Hlíňka Chick",
    "1F424": "dětská kočka",
    "1F425": "Čelní kuřátko",
    "1F426": "pták",
    "1F427": "tučňák",
    "1F54A FE0F": "holubice",
    "1F985": "orel",
    "1F986": "kachna",
    "1F9A2": "labuť",
    "1F989": "sova",
    "1F9A4": "dodo",
    "1FAB6": "Pírko",
    "1F9A9": "plameňák",
    "1F99A": "páv",
    "1F99C": "Papoušek",
    "1F438": "žába",
    "1F40A": "krokodýl",
    "1F422": "želva",
    "1F98E": "ještěrka",
    "1F40D": "had",
    "1F432": "drakový obličej",
    "1F409": "drak",
    "1F995": "Sauropod",
    "1F996": "T rex",
    "1F433": "Clona Whale",
    "1F40B": "velryba",
    "1F42C": "delfín",
    "1F9AD": "těsnění",
    "1F41F": "Ryba",
    "1F420": "tropická ryba",
    "1F421": "blowfish",
    "1F988": "žralok",
    "1F419": "chobotnice",
    "1F41A": "spirála shell",
    "1F40C": "hlemýžď",
    "1F98B": "motýl",
    "1F41B": "Chyba",
    "1F41C": "mravenec",
    "1F41D": "včelí med",
    "1FAB2": "brouk",
    "1F41E": "Lady Beetle",
    "1F997": "kriket",
    "1FAB3": "šváb",
    "1F577 FE0F": "pavouk",
    "1F578 FE0F": "pavoučí síť",
    "1F982": "Štír",
    "1F99F": "komár",
    "1FAB0": "létat",
    "1FAB1": "červ",
    "1F9A0": "mikrob",
    "1F490": "kytice",
    "1F338": "Třešňový květ",
    "1F4AE": "bílá květina",
    "1F3F5 FE0F": "růžice",
    "1F339": "růže",
    "1F940": "zvlhčená květina",
    "1F33A": "ibišek",
    "1F33B": "slunečnice",
    "1F33C": "květ",
    "1F337": "tulipán",
    "1F331": "sazenice",
    "1FAB4": "hrnková rostlina",
    "1F332": "vždy zelený strom",
    "1F333": "listnatý strom",
    "1F334": "palma",
    "1F335": "kaktus",
    "1F33E": "Snop rýže",
    "1F33F": "bylina",
    "2618 FE0F": "trojlístek",
    "1F340": "čtyř lístek",
    "1F341": "Javorový list",
    "1F342": "padlý list",
    "1F343": "list vlající ve větru",
    "1F347": "hrozny",
    "1F348": "meloun",
    "1F349": "vodní meloun",
    "1F34A": "mandarinka",
    "1F34B": "citrón",
    "1F34C": "banán",
    "1F34D": "ananas",
    "1F96D": "mango",
    "1F34E": "červené jablko",
    "1F34F": "zelené jablko",
    "1F350": "hruška",
    "1F351": "broskev",
    "1F352": "třešně",
    "1F353": "jahoda",
    "1FAD0": "borůvky",
    "1F95D": "kiwi",
    "1F345": "rajče",
    "1FAD2": "olivový",
    "1F965": "kokosový ořech",
    "1F951": "avokádo",
    "1F346": "lilek",
    "1F954": "brambor",
    "1F955": "mrkev",
    "1F33D": "Klas kukuřice",
    "1F336 FE0F": "pálivá paprička",
    "1FAD1": "paprika",
    "1F952": "okurka",
    "1F96C": "Listová zelená",
    "1F966": "brokolice",
    "1F9C4": "česnek",
    "1F9C5": "cibule",
    "1F344": "houba",
    "1F95C": "arašídy",
    "1F330": "Kaštan",
    "1F35E": "chléb",
    "1F950": "rohlík",
    "1F956": "bageta chléb",
    "1FAD3": "flatbread",
    "1F968": "preclík",
    "1F96F": "bagel",
    "1F95E": "palačinky",
    "1F9C7": "vafle",
    "1F9C0": "sýrový klín",
    "1F356": "maso na bosti",
    "1F357": "drůbeží noha",
    "1F969": "řez masa",
    "1F953": "slanina",
    "1F354": "hamburger",
    "1F35F": "hranolky",
    "1F355": "pizza",
    "1F32D": "párek v rohlíku",
    "1F96A": "sendvič",
    "1F32E": "taco",
    "1F32F": "burrito",
    "1FAD4": "Tamale",
    "1F959": "vycpaný flatbread",
    "1F9C6": "falafel",
    "1F95A": "vejce",
    "1F373": "vaření",
    "1F958": "Mělké pánve potravin",
    "1F372": "hrnec potravin",
    "1FAD5": "fondue",
    "1F963": "mísa s lžící",
    "1F957": "zelený salát",
    "1F37F": "popcorn",
    "1F9C8": "máslo",
    "1F9C2": "sůl",
    "1F96B": "konzervy",
    "1F371": "bento box",
    "1F358": "rýže cracker",
    "1F359": "rýžová koule",
    "1F35A": "vařená rýže",
    "1F35B": "kari rýže",
    "1F35C": "kouřící mísa",
    "1F35D": "špagety",
    "1F360": "Pečené sladké brambory",
    "1F362": "oden",
    "1F363": "sushi",
    "1F364": "smažená kreveta",
    "1F365": "Rybí dort s vírem",
    "1F96E": "měsíční koláč",
    "1F361": "Dango",
    "1F95F": "knedlík",
    "1F960": "sušenka štěstí",
    "1F961": "Takeout box",
    "1F980": "krab",
    "1F99E": "humr",
    "1F990": "krevety",
    "1F991": "oliheň",
    "1F9AA": "ústřice",
    "1F366": "měkký zmrzlina",
    "1F367": "ledová drť",
    "1F368": "zmrzlina",
    "1F369": "Kobliha",
    "1F36A": "cookie",
    "1F382": "narozeninový dort",
    "1F370": "krátkozraký",
    "1F9C1": "košíček",
    "1F967": "koláč",
    "1F36B": "tabulka čokolády",
    "1F36C": "bonbón",
    "1F36D": "lízátko",
    "1F36E": "pudink",
    "1F36F": "hrnec medu",
    "1F37C": "dětská láhev",
    "1F95B": "sklenice mléka",
    "2615": "horký nápoj",
    "1FAD6": "konvice na čaj",
    "1F375": "Šálek bez rukojeti",
    "1F376": "saké",
    "1F37E": "láhev s popping korku",
    "1F377": "sklenice na víno",
    "1F378": "koktejlové sklo",
    "1F379": "tropický nápoj",
    "1F37A": "pivo hrnek",
    "1F37B": "cinkání piva hrnky",
    "1F942": "sklenice cinkání",
    "1F943": "sklo sklenice",
    "1F964": "šálek se slámou",
    "1F9CB": "bublinkový čaj",
    "1F9C3": "nápoj box",
    "1F9C9": "mate",
    "1F9CA": "led",
    "1F962": "tyčinky",
    "1F37D FE0F": "Vidlička a nůž s deskou",
    "1F374": "Vidlička a nůž",
    "1F944": "lžíce",
    "1F52A": "kuchyňský nůž",
    "1F3FA": "amfora",
    "1F30D": "Glóbus zobrazující Evropu-Afrika",
    "1F30E": "zeměkoule ukazující Americas",
    "1F30F": "Glóbus ukazuje Asie-Austrálie",
    "1F310": "Glóbus s Meridians",
    "1F5FA FE0F": "mapa světa",
    "1F5FE": "Mapa Japonska",
    "1F9ED": "kompas",
    "1F3D4 FE0F": "zasněžená hora",
    "26F0 FE0F": "hora",
    "1F30B": "sopka",
    "1F5FB": "Mount Fuji",
    "1F3D5 FE0F": "kempování",
    "1F3D6 FE0F": "Pláž s deštníkem",
    "1F3DC FE0F": "poušť",
    "1F3DD FE0F": "opuštěný ostrov",
    "1F3DE FE0F": "národní park",
    "1F3DF FE0F": "stadión",
    "1F3DB FE0F": "klasická budova",
    "1F3D7 FE0F": "stavba budovy",
    "1F9F1": "cihlový",
    "1FAA8": "Skála",
    "1FAB5": "dřevo",
    "1F6D6": "chata",
    "1F3D8 FE0F": "domy",
    "1F3DA FE0F": "Derelict House",
    "1F3E0": "Dům",
    "1F3E1": "Dům se zahradou",
    "1F3E2": "kancelářská budova",
    "1F3E3": "Japonská pošta",
    "1F3E4": "pošta",
    "1F3E5": "nemocnice",
    "1F3E6": "banka",
    "1F3E8": "hotel",
    "1F3E9": "Love Hotel",
    "1F3EA": "Večerka",
    "1F3EB": "škola",
    "1F3EC": "obchodní dům",
    "1F3ED": "továrna",
    "1F3EF": "Japonský hrad",
    "1F3F0": "hrad",
    "1F492": "svatba",
    "1F5FC": "Tokyo Tower",
    "1F5FD": "Socha svobody",
    "26EA": "kostel",
    "1F54C": "mešita",
    "1F6D5": "Hinduistický chrám",
    "1F54D": "synagoga",
    "26E9 FE0F": "Shinto svatyně",
    "1F54B": "kaaba",
    "26F2": "kašna",
    "26FA": "stan",
    "1F301": "foggy",
    "1F303": "noc s hvězdami",
    "1F3D9 FE0F": "panoráma města",
    "1F304": "východ slunce nad horami",
    "1F305": "svítání",
    "1F306": "Panoráma města za soumraku",
    "1F307": "západ slunce",
    "1F309": "most v noci",
    "2668 FE0F": "horké prameny",
    "1F3A0": "karuselový kůň",
    "1F3A1": "Ruské kolo",
    "1F3A2": "horská dráha",
    "1F488": "Barber Pole",
    "1F3AA": "Circus stan",
    "1F682": "lokomotiva",
    "1F683": "železniční auto",
    "1F684": "vysokorychlostní vlak",
    "1F685": "rychlovlak",
    "1F686": "vlak",
    "1F687": "metro",
    "1F688": "lehká kolejnice",
    "1F689": "stanice",
    "1F68A": "tramvaj",
    "1F69D": "monoreil",
    "1F69E": "horská dráha",
    "1F68B": "tramvajové auto",
    "1F68C": "autobus",
    "1F68D": "Blížící se autobusem",
    "1F68E": "trolejbus",
    "1F690": "minibus",
    "1F691": "záchranná služba",
    "1F692": "hasičská stříkačka",
    "1F693": "policejní auto",
    "1F694": "protijedoucí policejní auto",
    "1F695": "taxi",
    "1F696": "protijedoucí taxi",
    "1F697": "automobil",
    "1F698": "protijedoucí automobil",
    "1F699": "sportovní užitkové vozidlo",
    "1F6FB": "pickup",
    "1F69A": "Dodávka Truck",
    "1F69B": "tahač s návěsem",
    "1F69C": "traktor",
    "1F3CE FE0F": "závodní auto",
    "1F3CD FE0F": "motocykl",
    "1F6F5": "motorový skútr",
    "1F9BD": "ruční invalidní vozík",
    "1F9BC": "motorizovaný vozík",
    "1F6FA": "Auto Rickshaw",
    "1F6B2": "jízdní kolo",
    "1F6F4": "Kick Scooter",
    "1F6F9": "skateboard",
    "1F6FC": "kolečkové brusle",
    "1F68F": "autobusová zastávka",
    "1F6E3 FE0F": "dálnice",
    "1F6E4 FE0F": "železnice",
    "1F6E2 FE0F": "olejový buben",
    "26FD": "palivové čerpadlo",
    "1F6A8": "policejní světlo",
    "1F6A5": "horizontální semafor",
    "1F6A6": "Vertikální semafor",
    "1F6D1": "stopka",
    "1F6A7": "konstrukce",
    "2693": "Kotva",
    "26F5": "plachetnice",
    "1F6F6": "kánoe",
    "1F6A4": "člun",
    "1F6F3 FE0F": "osobní loď",
    "26F4 FE0F": "trajekt",
    "1F6E5 FE0F": "motorový člun",
    "1F6A2": "loď",
    "2708 FE0F": "letoun",
    "1F6E9 FE0F": "malé letadlo",
    "1F6EB": "Odjezd letadla",
    "1F6EC": "Příjezd letadla",
    "1FA82": "padák",
    "1F4BA": "sedadlo",
    "1F681": "helikoptéra",
    "1F69F": "Zavěšení železnice",
    "1F6A0": "horské lanovky",
    "1F6A1": "anténní tramvaj",
    "1F6F0 FE0F": "družice",
    "1F680": "raketa",
    "1F6F8": "létající talíř",
    "1F6CE FE0F": "Bellhop Bell",
    "1F9F3": "zavazadlo",
    "231B": "Přesýpací hodiny",
    "23F3": "přesýpací hodiny nejsou hotové",
    "231A": "hodinky",
    "23F0": "budík",
    "23F1 FE0F": "stopky",
    "23F2 FE0F": "časovače hodin",
    "1F570 FE0F": "Mantelpiece Clock",
    "1F55B": "dvanáct hodin",
    "1F567": "dvanáct třicet",
    "1F550": "jedna hodina",
    "1F55C": "jedna třicet",
    "1F551": "dvě hodiny",
    "1F55D": "dva třicet",
    "1F552": "tři hodiny",
    "1F55E": "tři třicet",
    "1F553": "čtyři hodiny",
    "1F55F": "čtyři třicet",
    "1F554": "pět hodin",
    "1F560": "půl šesté",
    "1F555": "šest hodin",
    "1F561": "šest třicet",
    "1F556": "sedm hodin",
    "1F562": "sedm třicet",
    "1F557": "osm hodin",
    "1F563": "osm třicet",
    "1F558": "devět",
    "1F564": "devět třicet",
    "1F559": "deset",
    "1F565": "půl jedenácté",
    "1F55A": "jedenáct hodin",
    "1F566": "jedenáct třicet",
    "1F311": "nový měsíc",
    "1F312": "voskování půlměsíc",
    "1F313": "první čtvrtletí měsíc",
    "1F314": "voskování Gibbous Moon",
    "1F315": "úplněk",
    "1F316": "Waning Gibbous Moon",
    "1F317": "poslední čtvrtletí měsíc",
    "1F318": "Waning Crescent Moon",
    "1F319": "půlměsíc",
    "1F31A": "New Moon Face",
    "1F31B": "první čtvrtletí měsíc",
    "1F31C": "Poslední čtvrtletní měsíc",
    "1F321 FE0F": "teploměr",
    "2600 FE0F": "slunce",
    "1F31D": "Full Moon Face",
    "1F31E": "Slunce s obličejem",
    "1FA90": "Vyzváněná planeta",
    "2B50": "hvězda",
    "1F31F": "zářící hvězda",
    "1F320": "střelba hvězda",
    "1F30C": "mléčná dráha",
    "2601 FE0F": "mrak",
    "26C5": "slunce za mrakem",
    "26C8 FE0F": "Cloud s bleskem a deštěm",
    "1F324 FE0F": "Sun za malý mrak",
    "1F325 FE0F": "Slunce za velkým mrakem",
    "1F326 FE0F": "Sun Za dešťový mrak",
    "1F327 FE0F": "mrak s deštěm",
    "1F328 FE0F": "mrak se sněhem",
    "1F329 FE0F": "mrak s bleskem",
    "1F32A FE0F": "tornádo",
    "1F32B FE0F": "mlha",
    "1F32C FE0F": "větrná tvář",
    "1F300": "cyklón",
    "1F308": "duha",
    "1F302": "Uzavřený deštník",
    "2602 FE0F": "deštník",
    "2614": "Deštník s kapkami deště",
    "26F1 FE0F": "deštník na zemi",
    "26A1": "vysokého napětí",
    "2744 FE0F": "sněhová vločka",
    "2603 FE0F": "sněhulák",
    "26C4": "Sněhulák bez sněhu",
    "2604 FE0F": "kometa",
    "1F525": "oheň",
    "1F4A7": "kapička",
    "1F30A": "vodní vlna",
    "1F383": "Haloweenská dýně",
    "1F384": "vánoční strom",
    "1F386": "ohňostroj",
    "1F387": "prskavka",
    "1F9E8": "žabka",
    "2728": "jiskří",
    "1F388": "balón",
    "1F389": "Strana popper",
    "1F38A": "konfety míč",
    "1F38B": "Tanabata Tree",
    "1F38D": "borovice dekorace",
    "1F38E": "Japonské panenky",
    "1F38F": "kapr steamer",
    "1F390": "vítr zvonit",
    "1F391": "Ceremoniál prohlížení měsíce",
    "1F9E7": "červená obálka",
    "1F380": "stuha",
    "1F381": "zabalený dárek",
    "1F397 FE0F": "připomenutí stuha",
    "1F39F FE0F": "vstupenky",
    "1F3AB": "lístek",
    "1F396 FE0F": "vojenská medaile",
    "1F3C6": "trofej",
    "1F3C5": "sportovní medaile",
    "1F947": "1. místo Medal",
    "1F948": "2. místo medaile",
    "1F949": "3. místo medaile",
    "26BD": "fotbalový míč",
    "26BE": "baseball",
    "1F94E": "softball",
    "1F3C0": "Basketball",
    "1F3D0": "volejbal",
    "1F3C8": "americký fotbal",
    "1F3C9": "Rugby fotbal",
    "1F3BE": "tenis",
    "1F94F": "létající disk",
    "1F3B3": "kuželky",
    "1F3CF": "kriketová hra",
    "1F3D1": "pozemní hokej",
    "1F3D2": "lední hokej",
    "1F94D": "lakrosový",
    "1F3D3": "ping pong",
    "1F3F8": "badminton",
    "1F94A": "boxerské rukavice",
    "1F94B": "bojová umění uniforma",
    "1F945": "Cílová síť",
    "26F3": "vlajka v díře",
    "26F8 FE0F": "bruslit",
    "1F3A3": "rybářský prut",
    "1F93F": "potápěčská maska",
    "1F3BD": "běžící košile",
    "1F3BF": "lyže",
    "1F6F7": "sáně",
    "1F94C": "Curling Stone",
    "1F3AF": "přímý zásah",
    "1FA80": "yo-yo",
    "1FA81": "papírový drak",
    "1F3B1": "Bazén 8 míč",
    "1F52E": "Křišťálová koule",
    "1FA84": "Kouzelná hůlka",
    "1F9FF": "Nazar Amulet",
    "1F3AE": "video hra",
    "1F579 FE0F": "joystick",
    "1F3B0": "automat",
    "1F3B2": "Hra Die",
    "1F9E9": "kousek skládačky",
    "1F9F8": "Medvídek",
    "1FA85": "piñata",
    "1FA86": "Hnízdící panenky",
    "2660 FE0F": "rýčový oblek",
    "2665 FE0F": "Suť srdce",
    "2666 FE0F": "diamantový oblek",
    "2663 FE0F": "klubový oblek",
    "265F FE0F": "šachový pěšec",
    "1F0CF": "žolík",
    "1F004": "Mahjong Red Dragon",
    "1F3B4": "Květinové karty",
    "1F3AD": "múzických umění",
    "1F5BC FE0F": "zarámovaný obrázek",
    "1F3A8": "Umělec paleta",
    "1F9F5": "vlákno",
    "1FAA1": "jehla na šití",
    "1F9F6": "příze",
    "1FAA2": "uzel",
    "1F453": "brýle",
    "1F576 FE0F": "sluneční brýle",
    "1F97D": "ochranné brýle",
    "1F97C": "laboratorní plášť",
    "1F9BA": "Bezpečnostní vesta",
    "1F454": "vázanka",
    "1F455": "tričko",
    "1F456": "džíny",
    "1F9E3": "šátek",
    "1F9E4": "rukavice",
    "1F9E5": "kabát",
    "1F9E6": "ponožky",
    "1F457": "šaty",
    "1F458": "kimono",
    "1F97B": "Sari",
    "1FA71": "Jednodílné plavky",
    "1FA72": "slipy",
    "1FA73": "šortky",
    "1F459": "bikiny",
    "1F45A": "ženský šaty",
    "1F45B": "peněženka",
    "1F45C": "kabelka",
    "1F45D": "pytel spojky",
    "1F6CD FE0F": "nákupní tašky",
    "1F392": "batoh",
    "1FA74": "Thong Sandal",
    "1F45E": "muž je bota",
    "1F45F": "běh boty",
    "1F97E": "turistická bota",
    "1F97F": "plochá boty",
    "1F460": "s vysokým podpatkem",
    "1F461": "ženský sandál",
    "1FA70": "baletní boty",
    "1F462": "ženská bota",
    "1F451": "koruna",
    "1F452": "ženský klobouk",
    "1F3A9": "cylindr",
    "1F393": "maturitní čepice",
    "1F9E2": "účtovaný cap",
    "1FA96": "vojenská helma",
    "26D1 FE0F": "helma záchrany",
    "1F4FF": "modlitební korálky",
    "1F484": "rtěnka",
    "1F48D": "prsten",
    "1F48E": "Gem Stone",
    "1F507": "Tlumený reproduktor",
    "1F508": "Reproduktor Nízký objem",
    "1F509": "Medium hlasitosti reproduktoru",
    "1F50A": "Speaker High Volume",
    "1F4E2": "reproduktor",
    "1F4E3": "megafon",
    "1F4EF": "poštovní roh",
    "1F514": "zvonek",
    "1F515": "zvonek s lomítkem",
    "1F3BC": "hudební skóre",
    "1F3B5": "hudební nota",
    "1F3B6": "hudební noty",
    "1F399 FE0F": "Studio mikrofon",
    "1F39A FE0F": "Level Slider",
    "1F39B FE0F": "Ovládací knoflíky",
    "1F3A4": "mikrofon",
    "1F3A7": "sluchátka",
    "1F4FB": "rádio",
    "1F3B7": "saxofon",
    "1FA97": "akordeon",
    "1F3B8": "kytara",
    "1F3B9": "hudební klávesnice",
    "1F3BA": "trubka",
    "1F3BB": "housle",
    "1FA95": "bendžo",
    "1F941": "buben",
    "1FA98": "dlouhý buben",
    "1F4F1": "mobilní telefon",
    "1F4F2": "Mobilní telefon s šipkou",
    "260E FE0F": "telefon",
    "1F4DE": "telefonní přijímač",
    "1F4DF": "pager",
    "1F4E0": "fax",
    "1F50B": "baterie",
    "1F50C": "elektrická zástrčka",
    "1F4BB": "notebook",
    "1F5A5 FE0F": "stolní počítač",
    "1F5A8 FE0F": "tiskárna",
    "2328 FE0F": "klávesnice",
    "1F5B1 FE0F": "počítačová myš",
    "1F5B2 FE0F": "trackball",
    "1F4BD": "počítačový disk",
    "1F4BE": "disketa",
    "1F4BF": "optický disk",
    "1F4C0": "DVD",
    "1F9EE": "počitadlo",
    "1F3A5": "filmový fotoaparát",
    "1F39E FE0F": "Filmové rámečky",
    "1F4FD FE0F": "Filmový projektor",
    "1F3AC": "Klapka Board",
    "1F4FA": "televize",
    "1F4F7": "Fotoaparát",
    "1F4F8": "Fotoaparát s flash",
    "1F4F9": "videokamera",
    "1F4FC": "video kazeta",
    "1F50D": "Zvětšovací sklo nakloněné vlevo",
    "1F50E": "Zvětšovací sklo nakloněné vpravo",
    "1F56F FE0F": "svíčka",
    "1F4A1": "žárovka",
    "1F526": "svítilna",
    "1F3EE": "Červená papírová lucerna",
    "1FA94": "diya lampa",
    "1F4D4": "Notebook s dekorativním krytem",
    "1F4D5": "uzavřená kniha",
    "1F4D6": "otevřená kniha",
    "1F4D7": "zelená kniha",
    "1F4D8": "modrá kniha",
    "1F4D9": "oranžová kniha",
    "1F4DA": "knihy",
    "1F4D3": "notebook",
    "1F4D2": "účetní kniha",
    "1F4C3": "Stránka s Curl",
    "1F4DC": "svitek",
    "1F4C4": "Stránka nahoru nahoru",
    "1F4F0": "noviny",
    "1F5DE FE0F": "roled-up noviny",
    "1F4D1": "záložky záložky",
    "1F516": "záložka do knihy",
    "1F3F7 FE0F": "označení",
    "1F4B0": "peněžní taška",
    "1FA99": "mince",
    "1F4B4": "yen bankovka",
    "1F4B5": "Dollar bankovka",
    "1F4B6": "Euro bankovka",
    "1F4B7": "libra bankovka",
    "1F4B8": "peníze s křídly",
    "1F4B3": "kreditní karta",
    "1F9FE": "účtenka",
    "1F4B9": "Graf se zvyšuje s jenem",
    "2709 FE0F": "obálka",
    "1F4E7": "e-mailem",
    "1F4E8": "Příchozí obálka",
    "1F4E9": "Obálka se šipkou",
    "1F4E4": "Zásobník odeslání",
    "1F4E5": "Doručená pošta",
    "1F4E6": "balík",
    "1F4EB": "Uzavřená poštovní schránka se zvýšenou vlajkou",
    "1F4EA": "uzavřená poštovní schránka se sníženým vlajkou",
    "1F4EC": "Otevřete poštovní schránku se zvýšenou vlajkou",
    "1F4ED": "Otevřete poštovní schránku se sníženým vlajkou",
    "1F4EE": "poštovní schránka",
    "1F5F3 FE0F": "hlasovací lístek s hlasováním",
    "270F FE0F": "tužka",
    "2712 FE0F": "Černý Nib",
    "1F58B FE0F": "plnicí pero",
    "1F58A FE0F": "pero",
    "1F58C FE0F": "štětec",
    "1F58D FE0F": "pastelka",
    "1F4DD": "memo",
    "1F4BC": "aktovka",
    "1F4C1": "složka",
    "1F4C2": "Otevřete složku souborů",
    "1F5C2 FE0F": "Oddělovače indexu karet",
    "1F4C5": "kalendář",
    "1F4C6": "odtrhávací kalendář",
    "1F5D2 FE0F": "Spirála Poznámkový blok",
    "1F5D3 FE0F": "spirálový kalendář",
    "1F4C7": "index karty",
    "1F4C8": "Graf se zvyšuje",
    "1F4C9": "Schůzka Snížení",
    "1F4CA": "sloupcový graf",
    "1F4CB": "Schránka",
    "1F4CC": "pushpin",
    "1F4CD": "kulatý pushpin",
    "1F4CE": "kancelářská svorka",
    "1F587 FE0F": "propojené sponky",
    "1F4CF": "rovný vládce",
    "1F4D0": "trojúhelníkový vládce",
    "2702 FE0F": "nůžky",
    "1F5C3 FE0F": "Karta Box Box",
    "1F5C4 FE0F": "kartotéka",
    "1F5D1 FE0F": "odpadky",
    "1F512": "zamčený",
    "1F513": "odemčený",
    "1F50F": "uzamčeno perem",
    "1F510": "uzamčeno s klíčem",
    "1F511": "klíč",
    "1F5DD FE0F": "starý klíč",
    "1F528": "kladivo",
    "1FA93": "sekera",
    "26CF FE0F": "výběr",
    "2692 FE0F": "kladivo a pick",
    "1F6E0 FE0F": "kladivo a klíč",
    "1F5E1 FE0F": "dýka",
    "2694 FE0F": "zkřížené meče",
    "1F52B": "pistole",
    "1FA83": "bumerang",
    "1F3F9": "luk a šíp",
    "1F6E1 FE0F": "štít",
    "1FA9A": "truhlářská pila",
    "1F527": "klíč",
    "1FA9B": "šroubovák",
    "1F529": "matice a šroub",
    "2699 FE0F": "Ozubené kolo",
    "1F5DC FE0F": "svorka",
    "2696 FE0F": "váha váhy",
    "1F9AF": "Bílý třtina",
    "1F517": "odkaz",
    "26D3 FE0F": "řetězy",
    "1FA9D": "háček",
    "1F9F0": "panel nástrojů",
    "1F9F2": "magnet",
    "1FA9C": "žebřík",
    "2697 FE0F": "alembic",
    "1F9EA": "zkumavka",
    "1F9EB": "Petriho miska",
    "1F9EC": "DNA",
    "1F52C": "mikroskop",
    "1F52D": "dalekohled",
    "1F4E1": "Satelitní anténa",
    "1F489": "stříkačka",
    "1FA78": "kapka krve",
    "1F48A": "pilulka",
    "1FA79": "Lepicí obvaz",
    "1FA7A": "stetoskop",
    "1F6AA": "dveře",
    "1F6D7": "výtah",
    "1FA9E": "zrcadlo",
    "1FA9F": "okno",
    "1F6CF FE0F": "postel",
    "1F6CB FE0F": "gauč a lampa",
    "1FA91": "židle",
    "1F6BD": "toaleta",
    "1FAA0": "píst",
    "1F6BF": "sprcha",
    "1F6C1": "vana",
    "1FAA4": "Trap",
    "1FA92": "Břitva",
    "1F9F4": "láhev mléka",
    "1F9F7": "spínací špendlík",
    "1F9F9": "koště",
    "1F9FA": "košík",
    "1F9FB": "role papíru",
    "1FAA3": "Kbelík",
    "1F9FC": "mýdlo",
    "1FAA5": "zubní kartáček",
    "1F9FD": "houba na mytí",
    "1F9EF": "hasicí přístroj",
    "1F6D2": "nákupní košík",
    "1F6AC": "cigareta",
    "26B0 FE0F": "rakev",
    "1FAA6": "náhrobní kámen",
    "26B1 FE0F": "Pohřební urn",
    "1F5FF": "moai",
    "1FAA7": "plakát",
    "1F3E7": "ATM Sign",
    "1F6AE": "vrh v bin znamení",
    "1F6B0": "pitná voda",
    "267F": "Symbol invalidního vozíku",
    "1F6B9": "pánské toalety",
    "1F6BA": "ženský pokoj",
    "1F6BB": "toaleta",
    "1F6BC": "Baby Symbol",
    "1F6BE": "toaleta",
    "1F6C2": "pasová kontrola",
    "1F6C3": "celní",
    "1F6C4": "Návrh zavazadel",
    "1F6C5": "zanechat kufry",
    "26A0 FE0F": "Varování",
    "1F6B8": "děti překračující",
    "26D4": "vstup zakázán",
    "1F6AB": "zakázaný",
    "1F6B3": "Žádná jízdní kola",
    "1F6AD": "Nekouřit",
    "1F6AF": "zákaz skládky",
    "1F6B1": "non-pitná voda",
    "1F6B7": "Žádní chodci",
    "1F4F5": "Žádné mobilní telefony",
    "1F51E": "nikdo pod osmnáct",
    "2622 FE0F": "radioaktivní",
    "2623 FE0F": "biohazard",
    "2B06 FE0F": "šipka nahoru",
    "2197 FE0F": "Šipka vpravo",
    "27A1 FE0F": "pravá šipka",
    "2198 FE0F": "Šipka vpravo",
    "2B07 FE0F": "šipka dolů",
    "2199 FE0F": "Šipka dolů",
    "2B05 FE0F": "šipka vlevo",
    "2196 FE0F": "Šipka nahoru",
    "2195 FE0F": "Šipka nahoru",
    "2194 FE0F": "Šipka vlevo vpravo",
    "21A9 FE0F": "Zakřivení šipky vpravo",
    "21AA FE0F": "levé šipky zakřivení",
    "2934 FE0F": "Šipka vpravo zakřivení",
    "2935 FE0F": "Šipka vpravo zakřivení dolů",
    "1F503": "vertikální šipky ve směru hodinových ručiček",
    "1F504": "Tlačítko šipky proti směru hodinových ručiček",
    "1F519": "Zpět šipka",
    "1F51A": "Koncová šipka",
    "1F51B": "NA! šipka",
    "1F51C": "Brzy arrow",
    "1F51D": "Horní šipka",
    "1F6D0": "místo uctívání",
    "269B FE0F": "Symbol atomu",
    "1F549 FE0F": "omámený",
    "2721 FE0F": "hvězda Davida",
    "2638 FE0F": "Kolo Dharmy",
    "262F FE0F": "Yin Yang",
    "271D FE0F": "Latin Cross",
    "2626 FE0F": "ortodoxní kříž",
    "262A FE0F": "hvězda a půlměsíce",
    "262E FE0F": "Symbol míru",
    "1F54E": "Menorah",
    "1F52F": "tečkovaná šestipodlažná hvězda",
    "2648": "Aries",
    "2649": "Býk",
    "264A": "Blíženci",
    "264B": "Rakovina",
    "264C": "Lev",
    "264D": "Panna",
    "264E": "Váhy",
    "264F": "Štír",
    "2650": "Střelec",
    "2651": "Kozoroh",
    "2652": "Vodnář",
    "2653": "Ryby",
    "26CE": "Ophiuchus",
    "1F500": "Tlačítko shuffle stopy",
    "1F501": "tlačítko opakování",
    "1F502": "Opakujte jednotlivé tlačítko",
    "25B6 FE0F": "Tlačítko přehrávání",
    "23E9": "Tlačítko rychlého převíjení vpřed",
    "2,30E+10": "Tlačítko Fast-forwwer",
    "23ED FE0F": "další tlačítko stopy",
    "23EF FE0F": "Přehrajte nebo pauza",
    "25C0 FE0F": "Tlačítko Reverse",
    "23EA": "Rychlé zpětné tlačítko",
    "23EE FE0F": "poslední trať",
    "1F53C": "nahoru tlačítko",
    "23EB": "Tlačítko nahoru",
    "1F53D": "Tlačítko dolů",
    "23EC": "Tlačítko rychle dolů",
    "23F8 FE0F": "tlačítko pauza",
    "23F9 FE0F": "Tlačítko STOP",
    "23FA FE0F": "tlačítko záznamu",
    "23CF FE0F": "Tlačítko vysunutí",
    "1F3A6": "kino",
    "1F505": "Tlačítko DIM",
    "1F506": "Světlé tlačítko",
    "1F4F6": "Antény bary",
    "1F4F3": "Režim vibrací",
    "1F4F4": "Mobilní telefon off",
    "2640 FE0F": "ženské znamení",
    "2642 FE0F": "mužské znamení",
    "26A7 FE0F": "Symbol transgender",
    "2716 FE0F": "násobit",
    "2795": "Plus",
    "2796": "mínus",
    "2797": "rozdělit",
    "267E FE0F": "nekonečno",
    "203C FE0F": "dvojité vykřičník",
    "2049 FE0F": "vykřičník otazník",
    "2753": "otazník",
    "2754": "bílá otazníková značka",
    "2755": "Bílý vykřičník",
    "2757": "vykřičník",
    "3030 FE0F": "vlnitý pomlčka",
    "1F4B1": "směnárna",
    "1F4B2": "Těžký znak dolaru",
    "2695 FE0F": "lékařský symbol",
    "267B FE0F": "Recyklační Symbol",
    "269C FE0F": "fleur-de-lis",
    "1F531": "Emblem Trident",
    "1F4DB": "jmenovka",
    "1F530": "Japonský symbol pro začátečníky",
    "2B55": "Dutý červený kruh",
    "2705": "Zaškrtněte tlačítko",
    "2611 FE0F": "Zaškrtněte políčko s kontrolou",
    "2714 FE0F": "zaškrtnutí značky",
    "274C": "křížová značka",
    "274E": "Tlačítko Cross Mark",
    "27B0": "kudrnaté smyčky",
    "27BF": "dvojitá kudrnatá smyčka",
    "303D FE0F": "Střídavná část Mark",
    "2733 FE0F": "osmstranný hvězdičku",
    "2734 FE0F": "osm-špičatá hvězda",
    "2747 FE0F": "Jiskra",
    "00A9 FE0F": "autorská práva",
    "00AE FE0F": "registrovaný",
    "2122 FE0F": "obchodní značka",
    "002A FE0F 20E3": "KeyCap: *",
    "0030 FE0F 20E3": "KEYCAP: 0",
    "0031 FE0F 20E3": "KeyCap: 1",
    "0032 FE0F 20E3": "KeyCap: 2",
    "0033 FE0F 20E3": "KeyCap: 3",
    "0034 FE0F 20E3": "KEYCAP: 4",
    "0035 FE0F 20E3": "KeyCap: 5",
    "0036 FE0F 20E3": "KeyCap: 6",
    "0037 FE0F 20E3": "KEYCAP: 7",
    "0038 FE0F 20E3": "KeyCap: 8",
    "0039 FE0F 20E3": "KeyCap: 9",
    "1F51F": "KeyCap: 10",
    "1F520": "vstup latinské velká písmena",
    "1F521": "vstup latinské malé písmeno",
    "1F522": "vstupní čísla",
    "1F523": "vstupní symboly",
    "1F524": "vstupní latinské dopisy",
    "1F170 FE0F": "Tlačítko (typ krve)",
    "1F18E": "Tlačítko AB (typ krve)",
    "1F171 FE0F": "B tlačítko (typ krve)",
    "1F191": "CL tlačítko",
    "1F192": "Tlačítko Cool",
    "1F193": "Tlačítko zdarma",
    "2139 FE0F": "informace",
    "1F194": "ID ID",
    "24C2 FE0F": "kroužil M",
    "1F195": "Nové tlačítko",
    "1F196": "Tlačítko ng",
    "1F17E FE0F": "O tlačítko (krevní typ)",
    "1F197": "Tlačítko OK",
    "1F17F FE0F": "P tlačítko",
    "1F198": "Tlačítko SOS",
    "1F199": "NAHORU! knoflík",
    "1F19A": "Vs tlačítko",
    "1F201": "Tlačítko japonské „zde“",
    "1F202 FE0F": "Tlačítko japonské „Service Charge“",
    "1F237 FE0F": "Tlačítko „Měsíční částka“",
    "1F236": "Tlačítko Japonec „Ne bezplatně“",
    "1F22F": "Tlačítko japonské „vyhrazené“",
    "1F250": "Tlačítko japonské „vyjednávání“",
    "1F239": "Tlačítko japonské „sleva“",
    "1F21A": "Tlačítko japonské „zdarma“",
    "1F232": "Tlačítko japonské „zakázané“",
    "1F251": "Tlačítko japonské „přijatelné“",
    "1F238": "Tlačítko japonské „aplikace“",
    "1F234": "Tlačítko japonské „pasáže“",
    "1F233": "Tlačítko japonské „volné místo“",
    "3297 FE0F": "Tlačítko japonské „blahopřání“",
    "3299 FE0F": "Tlačítko japonské „tajné“",
    "1F23A": "Tlačítko „Open for Business“",
    "1F235": "Tlačítko japonské „žádné volné místo“",
    "1F534": "červený kruh",
    "1F7E0": "oranžový kruh",
    "1F7E1": "žlutý kruh",
    "1F7E2": "zelený kruh",
    "1F535": "modrý kruh",
    "1F7E3": "fialový kruh",
    "1F7E4": "hnědý kruh",
    "26AB": "černý kruh",
    "26AA": "bílý kruh",
    "1F7E5": "Rudé náměstí",
    "1F7E7": "Orange Square",
    "1F7E8": "Žlutý čtverec",
    "1F7E9": "zelený čtverec",
    "1F7E6": "Modrý Square",
    "1F7EA": "Purple Square",
    "1F7EB": "Brown Square",
    "2B1B": "Černý velký Square",
    "2B1C": "Bílý velký Square",
    "25FC FE0F": "Černý medium Square",
    "25FB FE0F": "Bílé médium Square",
    "25FE": "Černé střední malé náměstí",
    "25FD": "Bílé střední malé náměstí",
    "25AA FE0F": "Černý malý Square",
    "25AB FE0F": "bílý malý čtverec",
    "1F536": "Velký oranžový diamant",
    "1F537": "Velký modrý diamant",
    "1F538": "malý oranžový diamant",
    "1F539": "malý modrý diamant",
    "1F53A": "Červený trojúhelník se ukázal nahoru",
    "1F53B": "Červený trojúhelník ukázal dolů",
    "1F4A0": "Diamant s tečkou",
    "1F518": "knoflík",
    "1F533": "bílé čtvercové tlačítko",
    "1F532": "Černý čtverec tlačítko",
    "1F3C1": "kostkovaná vlajka",
    "1F6A9": "trojúhelníková vlajka",
    "1F38C": "Překřížené vlajky",
    "1F3F4": "Černá vlajka",
    "1F3F3 FE0F": "bílá vlajka",
    "1F3F3 FE0F 200D 1F308": "duhová vlajka",
    "1F3F3 FE0F 200D 26A7 FE0F": "Transgender Flag",
    "1F3F4 200D 2620 FE0F": "pirátská vlajka",
    "1F1E6 1F1E8": "Vlajka: Island Ascension",
    "1F1E6 1F1E9": "Vlajka: Andorra",
    "1F1E6 1F1EA": "Vlajka: Spojené arabské emiráty",
    "1F1E6 1F1EB": "Vlajka: Afghánistán",
    "1F1E6 1F1EC": "Vlajka: Antigua & Barbuda",
    "1F1E6 1F1EE": "Vlajka: Anguilla",
    "1F1E6 1F1F1": "Vlajka: Albánie",
    "1F1E6 1F1F2": "Vlajka: Arménie",
    "1F1E6 1F1F4": "Vlajka: Angola",
    "1F1E6 1F1F6": "Vlajka: Antarktida",
    "1F1E6 1F1F7": "Vlajka: Argentina",
    "1F1E6 1F1F8": "Vlajka: Americký Samoa",
    "1F1E6 1F1F9": "Vlajka: Rakousko",
    "1F1E6 1F1FA": "Vlajka: Austrálie",
    "1F1E6 1F1FC": "Vlajka: Aruba",
    "1F1E6 1F1FD": "Vlajka: Ålandské ostrovy",
    "1F1E6 1F1FF": "Vlajka: Ázerbájdžán",
    "1F1E7 1F1E6": "Vlajka: Bosna a Hercegovina",
    "1F1E7 1F1E7": "Vlajka: Barbados",
    "1F1E7 1F1E9": "Vlajka: Bangladéš",
    "1F1E7 1F1EA": "Vlajka: Belgie",
    "1F1E7 1F1EB": "Vlajka: Burkina Faso",
    "1F1E7 1F1EC": "Vlajka: Bulharsko",
    "1F1E7 1F1ED": "Vlajka: Bahrajn",
    "1F1E7 1F1EE": "Vlajka: Burundi",
    "1F1E7 1F1EF": "Vlajka: Benin",
    "1F1E7 1F1F1": "vlajka: St. Barthélemy",
    "1F1E7 1F1F2": "Vlajka: Bermuda",
    "1F1E7 1F1F3": "Vlajka: Brunej",
    "1F1E7 1F1F4": "Vlajka: Bolívie",
    "1F1E7 1F1F6": "Vlajka: Karibik Nizozemsko",
    "1F1E7 1F1F7": "Vlajka: Brazílie",
    "1F1E7 1F1F8": "Vlajka: Bahamas",
    "1F1E7 1F1F9": "Vlajka: Bhútán",
    "1F1E7 1F1FB": "Vlajka: Bouvet Island",
    "1F1E7 1F1FC": "Vlajka: Botswana",
    "1F1E7 1F1FE": "Vlajka: Bělorusko",
    "1F1E7 1F1FF": "Vlajka: Belize",
    "1F1E8 1F1E6": "Vlajka: Kanada",
    "1F1E8 1F1E8": "Vlajka: Cocos (Keeling) Ostrovy",
    "1F1E8 1F1E9": "Vlajka: Kongo - Kinshasa",
    "1F1E8 1F1EB": "Vlajka: Středoafrická republika",
    "1F1E8 1F1EC": "Vlajka: Kongo - Brazzaville",
    "1F1E8 1F1ED": "Vlajka: Švýcarsko",
    "1F1E8 1F1EE": "Vlajka: Pobřeží slonoviny",
    "1F1E8 1F1F0": "Vlajka: Cookové ostrovy",
    "1F1E8 1F1F1": "Vlajka: Chile",
    "1F1E8 1F1F2": "Vlajka: Kamerun",
    "1F1E8 1F1F3": "Vlajka: Čína",
    "1F1E8 1F1F4": "Vlajka: Kolumbie",
    "1F1E8 1F1F5": "Vlajka: Cliperton Island",
    "1F1E8 1F1F7": "Vlajka: Costa Rica",
    "1F1E8 1F1FA": "Vlajka: Kuba",
    "1F1E8 1F1FB": "Vlajka: Cape Verde",
    "1F1E8 1F1FC": "Vlajka: Curaçao",
    "1F1E8 1F1FD": "Vlajka: Vánoční ostrov",
    "1F1E8 1F1FE": "Vlajka: Kypr",
    "1F1E8 1F1FF": "Vlajka: Česko",
    "1F1E9 1F1EA": "Vlajka: Německo",
    "1F1E9 1F1EC": "Vlajka: Diego Garcia",
    "1F1E9 1F1EF": "Vlajka: Džibuti",
    "1F1E9 1F1F0": "Vlajka: Dánsko",
    "1F1E9 1F1F2": "Vlajka: Dominika",
    "1F1E9 1F1F4": "Vlajka: Dominikánská republika",
    "1F1E9 1F1FF": "Vlajka: Alžírsko",
    "1F1EA 1F1E6": "Vlajka: Ceuta & Melilla",
    "1F1EA 1F1E8": "Vlajka: Ekvádor",
    "1F1EA 1F1EA": "Vlajka: Estonsko",
    "1F1EA 1F1EC": "Vlajka: Egypt",
    "1F1EA 1F1ED": "Vlajka: Západní Sahara",
    "1F1EA 1F1F7": "Vlajka: Eritrea",
    "1F1EA 1F1F8": "Vlajka: Španělsko",
    "1F1EA 1F1F9": "Vlajka: Etiopie",
    "1F1EA 1F1FA": "Vlajka: Evropská unie",
    "1F1EB 1F1EE": "Vlajka: Finsko",
    "1F1EB 1F1EF": "Vlajka: Fidži",
    "1F1EB 1F1F0": "Vlajka: Falklandské ostrovy",
    "1F1EB 1F1F2": "Vlajka: Mikronésie",
    "1F1EB 1F1F4": "Vlajka: Faerské ostrovy",
    "1F1EB 1F1F7": "Vlajka: Francie",
    "1F1EC 1F1E6": "Vlajka: Gabon",
    "1F1EC 1F1E7": "Vlajka: Velká Británie",
    "1F1EC 1F1E9": "Vlajka: Grenada",
    "1F1EC 1F1EA": "Vlajka: Gruzie",
    "1F1EC 1F1EB": "Vlajka: Francouzská Guyana",
    "1F1EC 1F1EC": "Vlajka: Guernsey",
    "1F1EC 1F1ED": "Vlajka: Ghana",
    "1F1EC 1F1EE": "Vlajka: Gibraltar",
    "1F1EC 1F1F1": "Vlajka: Grónsko",
    "1F1EC 1F1F2": "Vlajka: Gambie",
    "1F1EC 1F1F3": "Vlajka: Guinea",
    "1F1EC 1F1F5": "Vlajka: Guadeloupe",
    "1F1EC 1F1F6": "Vlajka: Rovníková Guinea",
    "1F1EC 1F1F7": "Vlajka: Řecko",
    "1F1EC 1F1F8": "Vlajka: South Georgia & South Sandwich Islands",
    "1F1EC 1F1F9": "Vlajka: Guatemala",
    "1F1EC 1F1FA": "Vlajka: Guam",
    "1F1EC 1F1FC": "Vlajka: Guinea-Bissau",
    "1F1EC 1F1FE": "Vlajka: Guyana",
    "1F1ED 1F1F0": "Vlajka: Hong Kong SAR Čína",
    "1F1ED 1F1F2": "Vlajka: Slyšená a McDonald Islands",
    "1F1ED 1F1F3": "Vlajka: Honduras",
    "1F1ED 1F1F7": "Vlajka: Chorvatsko",
    "1F1ED 1F1F9": "Vlajka: Haiti",
    "1F1ED 1F1FA": "Vlajka: Maďarsko",
    "1F1EE 1F1E8": "Vlajka: Kanárské ostrovy",
    "1F1EE 1F1E9": "Vlajka: Indonésie",
    "1F1EE 1F1EA": "Vlajka: Irsko",
    "1F1EE 1F1F1": "Vlajka: Izrael",
    "1F1EE 1F1F2": "Vlajka: Isle of Man",
    "1F1EE 1F1F3": "Vlajka: Indie",
    "1F1EE 1F1F4": "Vlajka: Britské území Indického oceánu",
    "1F1EE 1F1F6": "Vlajka: Irák",
    "1F1EE 1F1F7": "Vlajka: Írán",
    "1F1EE 1F1F8": "Vlajka: Island",
    "1F1EE 1F1F9": "Vlajka: Itálie",
    "1F1EF 1F1EA": "Vlajka: Jersey",
    "1F1EF 1F1F2": "Vlajka: Jamajka",
    "1F1EF 1F1F4": "Vlajka: Jordánsko",
    "1F1EF 1F1F5": "Vlajka: Japonsko",
    "1F1F0 1F1EA": "Vlajka: Keňa",
    "1F1F0 1F1EC": "Vlajka: Kyrgyzstán",
    "1F1F0 1F1ED": "Vlajka: Kambodža",
    "1F1F0 1F1EE": "Vlajka: Kiribati",
    "1F1F0 1F1F2": "Vlajka: Comoros",
    "1F1F0 1F1F3": "Vlajka: St. Kitts & Nevis",
    "1F1F0 1F1F5": "Vlajka: Severní Korea",
    "1F1F0 1F1F7": "Vlajka: Jižní Korea",
    "1F1F0 1F1FC": "Vlajka: Kuvajt",
    "1F1F0 1F1FE": "Vlajka: Kajmanské ostrovy",
    "1F1F0 1F1FF": "Vlajka: Kazachstán",
    "1F1F1 1F1E6": "Vlajka: Laos",
    "1F1F1 1F1E7": "Vlajka: Libanon",
    "1F1F1 1F1E8": "Vlajka: St. Lucia",
    "1F1F1 1F1EE": "Vlajka: Lichtenštejnsko",
    "1F1F1 1F1F0": "Vlajka: Srí Lanka",
    "1F1F1 1F1F7": "Vlajka: Libérie",
    "1F1F1 1F1F8": "Vlajka: Lesotho",
    "1F1F1 1F1F9": "Vlajka: Litva",
    "1F1F1 1F1FA": "Vlajka: Lucembursko",
    "1F1F1 1F1FB": "Vlajka: Lotyšsko",
    "1F1F1 1F1FE": "Vlajka: Libye",
    "1F1F2 1F1E6": "Vlajka: Maroko",
    "1F1F2 1F1E8": "Vlajka: Monako",
    "1F1F2 1F1E9": "Vlajka: Moldavsko",
    "1F1F2 1F1EA": "Vlajka: Černá Hora",
    "1F1F2 1F1EB": "Vlajka: St. Martin",
    "1F1F2 1F1EC": "Vlajka: Madagaskar",
    "1F1F2 1F1ED": "Vlajka: Marshallovy ostrovy",
    "1F1F2 1F1F0": "Vlajka: Severní Makedonie",
    "1F1F2 1F1F1": "Vlajka: Mali",
    "1F1F2 1F1F2": "Vlajka: Myanmar (Barma)",
    "1F1F2 1F1F3": "Vlajka: Mongolsko",
    "1F1F2 1F1F4": "Vlajka: Macao SAR Čína",
    "1F1F2 1F1F5": "Vlajka: Severní Mariánské ostrovy",
    "1F1F2 1F1F6": "Vlajka: Martinik",
    "1F1F2 1F1F7": "Vlajka: Mauritánie",
    "1F1F2 1F1F8": "Vlajka: Montserrat",
    "1F1F2 1F1F9": "Vlajka: Malta",
    "1F1F2 1F1FA": "Vlajka: Mauricius",
    "1F1F2 1F1FB": "Vlajka: Maledivy",
    "1F1F2 1F1FC": "Vlajka: Malawi",
    "1F1F2 1F1FD": "Vlajka: Mexiko",
    "1F1F2 1F1FE": "Vlajka: Malajsie",
    "1F1F2 1F1FF": "Vlajka: Mosambike",
    "1F1F3 1F1E6": "Vlajka: Namibie",
    "1F1F3 1F1E8": "Vlajka: Nová Kaledonie",
    "1F1F3 1F1EA": "Vlajka: Niger",
    "1F1F3 1F1EB": "Vlajka: Norfolk Island",
    "1F1F3 1F1EC": "Vlajka: Nigérie",
    "1F1F3 1F1EE": "Vlajka: Nikaragua",
    "1F1F3 1F1F1": "Vlajka: Nizozemsko",
    "1F1F3 1F1F4": "Vlajka: Norsko",
    "1F1F3 1F1F5": "Vlajka: Nepál",
    "1F1F3 1F1F7": "Vlajka: Nauru",
    "1F1F3 1F1FA": "Vlajka: Niue",
    "1F1F3 1F1FF": "Vlajka: Nový Zéland",
    "1F1F4 1F1F2": "Vlajka: Omán",
    "1F1F5 1F1E6": "Vlajka: Panama",
    "1F1F5 1F1EA": "Vlajka: Peru",
    "1F1F5 1F1EB": "Vlajka: Francouzská Polynésie",
    "1F1F5 1F1EC": "Vlajka: Papua Nová Guinea",
    "1F1F5 1F1ED": "Vlajka: Filipíny",
    "1F1F5 1F1F0": "Vlajka: Pákistán",
    "1F1F5 1F1F1": "Vlajka: Polsko",
    "1F1F5 1F1F2": "Vlajka: St. Pierre & Miquelon",
    "1F1F5 1F1F3": "Vlajka: Pitcairnovy ostrovy",
    "1F1F5 1F1F7": "Vlajka: Puerto Rico",
    "1F1F5 1F1F8": "Vlajka: Palestinská území",
    "1F1F5 1F1F9": "Vlajka: Portugalsko",
    "1F1F5 1F1FC": "Vlajka: Palau",
    "1F1F5 1F1FE": "Vlajka: Paraguay",
    "1F1F6 1F1E6": "Vlajka: Katar",
    "1F1F7 1F1EA": "Vlajka: Réunion",
    "1F1F7 1F1F4": "Vlajka: Rumunsko",
    "1F1F7 1F1F8": "Vlajka: Srbsko",
    "1F1F7 1F1FA": "Vlajka: Rusko",
    "1F1F7 1F1FC": "Vlajka: Rwanda",
    "1F1F8 1F1E6": "Vlajka: Saúdská Arábie",
    "1F1F8 1F1E7": "Vlajka: Šalamounovy ostrovy",
    "1F1F8 1F1E8": "Vlajka: Seychely",
    "1F1F8 1F1E9": "Vlajka: Súdán",
    "1F1F8 1F1EA": "Vlajka: Švédsko",
    "1F1F8 1F1EC": "Vlajka: Singapur",
    "1F1F8 1F1ED": "Vlajka: St. Helena",
    "1F1F8 1F1EE": "Vlajka: Slovinsko",
    "1F1F8 1F1EF": "Vlajka: Svalbard & Jan Mayen",
    "1F1F8 1F1F0": "Vlajka: Slovensko",
    "1F1F8 1F1F1": "Vlajka: Sierra Leone",
    "1F1F8 1F1F2": "Vlajka: San Marino",
    "1F1F8 1F1F3": "Vlajka: Senegal",
    "1F1F8 1F1F4": "Vlajka: Somálsko",
    "1F1F8 1F1F7": "Vlajka: Surinam",
    "1F1F8 1F1F8": "Vlajka: Jižní Súdán",
    "1F1F8 1F1F9": "Vlajka: São Tomé & Principe",
    "1F1F8 1F1FB": "Vlajka: El Salvador",
    "1F1F8 1F1FD": "Vlajka: Sint Maarten",
    "1F1F8 1F1FE": "Vlajka: Sýrie",
    "1F1F8 1F1FF": "Vlajka: Eswatini",
    "1F1F9 1F1E6": "Vlajka: Tristan da Cunha",
    "1F1F9 1F1E8": "Vlajka: Turks & Caicos Islands",
    "1F1F9 1F1E9": "Vlajka: Čad",
    "1F1F9 1F1EB": "Vlajka: Francouzská jižní území",
    "1F1F9 1F1EC": "Vlajka: TOGO",
    "1F1F9 1F1ED": "Vlajka: Thajsko",
    "1F1F9 1F1EF": "Vlajka: Tádžikistán",
    "1F1F9 1F1F0": "Vlajka: Tokelau",
    "1F1F9 1F1F1": "Vlajka: Timor-Leste",
    "1F1F9 1F1F2": "Vlajka: Turkmenistán",
    "1F1F9 1F1F3": "Vlajka: Tunisko",
    "1F1F9 1F1F4": "Vlajka: Tonga",
    "1F1F9 1F1F7": "Vlajka: Turecko",
    "1F1F9 1F1F9": "Vlajka: Trinidad & tobago",
    "1F1F9 1F1FB": "Vlajka: Tuvalu",
    "1F1F9 1F1FC": "Vlajka: Tchaj-wan",
    "1F1F9 1F1FF": "Vlajka: Tanzanie",
    "1F1FA 1F1E6": "Vlajka: Ukrajina",
    "1F1FA 1F1EC": "Vlajka: Uganda",
    "1F1FA 1F1F2": "Vlajka: U.S. Odlehlé ostrovy",
    "1F1FA 1F1F3": "Vlajka: Organizace spojených národů",
    "1F1FA 1F1F8": "Vlajka: Spojené státy americké",
    "1F1FA 1F1FE": "Vlajka: Uruguay",
    "1F1FA 1F1FF": "Vlajka: Uzbekistán",
    "1F1FB 1F1E6": "Vlajka: Vatikán",
    "1F1FB 1F1E8": "Vlajka: St. Vincent & Grenadines",
    "1F1FB 1F1EA": "Vlajka: Venezuela",
    "1F1FB 1F1EC": "Vlajka: Britské Panenské ostrovy",
    "1F1FB 1F1EE": "Vlajka: U.S. Panenské ostrovy",
    "1F1FB 1F1F3": "Vlajka: Vietnam",
    "1F1FB 1F1FA": "Vlajka: Vanuatu",
    "1F1FC 1F1EB": "Vlajka: Wallis & Futuna",
    "1F1FC 1F1F8": "Vlajka: Samoa",
    "1F1FD 1F1F0": "Vlajka: Kosovo",
    "1F1FE 1F1EA": "Vlajka: Jemen",
    "1F1FE 1F1F9": "Vlajka: Mayotte",
    "1F1FF 1F1E6": "Vlajka: Jižní Afrika",
    "1F1FF 1F1F2": "Vlajka: Zambie",
    "1F1FF 1F1FC": "Vlajka: Zimbabwe",
    "1F3F4 E0067 E0062 E0065 E006E E0067 E007F": "Vlajka: Anglie",
};
