import React, { FunctionComponent } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { List, Footer, Menu, EmojiList, LocationProvider, AlertProvider, FontReset } from "./components";
import { charInfoList, rateInfoList } from "./constants";
import "./App.css";

export const App: FunctionComponent = () => (
    <AlertProvider>
        <Router forceRefresh={true}>
            <LocationProvider>
                {({ pathname }) => (
                    <div className={`app app-${pathname.replace("/", "")}`}>
                        <Menu />
                        <Switch>
                            <Route path="/emoji">
                                <EmojiList />
                            </Route>
                            <Route path="/hodnoceni">
                                <List characterList={rateInfoList} />
                            </Route>
                            <Route path="/">
                                <FontReset>
                                    <List characterList={charInfoList} />
                                </FontReset>
                            </Route>
                        </Switch>
                        <Footer />
                    </div>
                )}
            </LocationProvider>
        </Router>
    </AlertProvider >
);
